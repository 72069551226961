<template>
    <SearchListArchive />
</template>

<script>
import SearchListArchive from '../components/SearchListArchive.vue'

export default {
    components: {
        SearchListArchive
    }
}
</script>

